const cities = [
'Santander',
'Torrelavega',
'Castro Urdiales',
'Camargo',
'Pielagos',
'El Astillero',
'Laredo',
'Santoña',
'Los Corrales de Buelna	',
'Reinosa',
'Santa Cruz de Bezana',
'Cabezon de la Sal',
'Reocin',
'Colindres',
'Suances',
'Santa Maria de Cayon',
'Medio Cudeyo',
'Marina de Cudeyo',
'San Vicente de la Barquera	',
'Ribamontan al Mar',
'Santillana del Mar',
'Cartes',
'Campoo de Enmedio',
'Polanco',
'Miengo',
'Ampuero',
'Villaescusa',
'Barcena de Cicero',
'Entrambasaguas',
'Val de San Vicente',
'Alfoz de Lloredo',
'Puente Viesgo',
'Comillas',
'Valdaliga',
'Lierganes',
'Noja',
'Ramales de la Victoria',
'Voto',
'San Felices de Buelna',
'Corvera de Toranzo	',
'Ribamontan al Monte',
'Mazcuerras',
'Selaya',
'Hermandad de Campoo de Suso',
'Guriezo',
'Arnuero',
'Arenas de Iguña',
'Bareyo',
'Molledo',
'Castañeda',
'Villacarriedo',
'Penagos',
'Potes',
'Riotuerto',
'Limpias',
'Soba',
'Meruelo',
'Santiurde de Toranzo',
'Argoños',
'Valdeolea',
'Hazas de Cesto',
'Rionansa',
'Cillorigo de Liebana',
'Valderredible',
'Ruesga',
'Villafufre',
'Cabuerniga',
'Camaleño',
'Solorzano',
'Ruente',
'Liendo',
'Rasines',
'Vega de Pas',
'Vega de Liébana',
'Luena',
'Udias',
'Barcena de Pie de Concha',
'Escalante',
'Ruiloba',
'Campoo de Yuso',
'Herrerias',
'Cabezon de Liébana',
'Cieza',
'San Pedro del Romeral',
'Arredondo',
'Saro',
'Miera',
'San Roque de Riomiera',
'Los Tojos',
'Valle de Villaverde',
'Anievas',
'Peñarrubia',
'Pesaguero',
'Santiurde de Reinosa',
'Lamason',
'Valdeprado del Rio',
'Las Rozas de Valdearroyo',
'Polaciones',
'Tudanca',
'San Miguel de Aguayo',
'Pesquera',
'Tresviso'
];

const citiesSort = [
    'Santander',
    'Torrelavega',
    'Castro Urdiales',
    'Camargo',
    'Pielagos',
    'El Astillero',
    'Laredo',
    'Santoña',
    'Los Corrales de Buelna	',
    'Reinosa',
    'Santa Cruz de Bezana',
    'Cabezon de la Sal',
    'Reocin',
    'Colindres',
    'Suances',
    'Santa Maria de Cayon',
    'Solares',
    'Marina de Cudeyo',
    'San Vicente de la Barquera	',
    'Ribamontan al Mar',
    'Santillana del Mar',
    'Cartes',
    'Campoo de Enmedio',
    'Polanco',
    'Miengo',
    'Ampuero',
    'Madrid	 ',
'Barcelona	',
'Valencia	',
'Sevilla	',
'Zaragoza	',
'Málaga	',
'Murcia	 ',
'Palma de Mallorca	 ',
'Las Palmas de Gran Canaria	',
'Bilbao	 ',
'Alicante',
'Córdoba',
'Valladolid',
'Vitoria',
'La Coruña',
'Granada	',
'Oviedo	',
'Santa Cruz de Tenerife',
'Pamplona',
'Almería	',
'San Sebastián	',
'Burgos',
'Albacete	',
'Castellón de la Plana	',
'Logroño',
'Badajoz',
'Salamanca	',
'Huelva',
'Lérida	 ',
'Tarragona	',
'León	',
'Cádiz	',
'Jaén	',
'Orense',
'Gerona	',
'Lugo	',
'Cáceres	',
'Melilla	',
'Guadalajara	',
'Toledo	 ',
'Ceuta	',
'Pontevedra',
'Palencia	',
'Ciudad Real',
'Zamora	',
'Ávila	 ',
'Cuenca	',
'Huesca	 ',
'Segovia	',
'Soria	',
'Teruel	'
    ];

const works = [ 'Mudanzas', 'Vaciados de pisos', 'Reformas', 'Pintura', 'Recogida muebles', 'Limpiezas' ];

export default{ cities, works, citiesSort }