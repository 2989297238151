<template>
    <div class="ex-basic-1 pt-5"><br><br>
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <div><h3> Preguntas frecuentes Mudanzas Reto:</h3></div><br><br>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Qué recoge <strong>Mudanzas Reto?</strong></h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Quién puede recoger <b>muebles viejos</b> o <b>electrodomésticos en desuso</b>?</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Cuánto cobra <b>Reto</b> por hacer una mudanza?</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Cuánto cuesta una mudanza en <b>Santander</b>?</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Cuánto cuesta una mudanza de <b>Madrid a Santander</b>?</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">¿Dónde puedo llamar para que recojan un colchon?</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">Telefono de Mudanzas Reto 642 57 52 21</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">Limpieza de viviendas afectadas por sindrome de diogenes</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">Desalojos y limpiezas de oficinas y naves</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">Servicio de vaciado de pisos rápido</h5>
                                    </router-link>
                            </li>
                        </ul>
                        <ul class="list-unstyled li-space-lg mb-5">
                            <li class="d-flex">
                                    <i class="fas fa-square"></i>
                                    <router-link to="/" class="decoration_none">
                                        <h5 class="flex-grow-1">Mudanzas Reto recogida muebles</h5>
                                    </router-link>
                            </li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.decoration_none{
    text-decoration: none;
}
</style>